var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',[_c('DxDataGrid',{ref:"kpiScheGrid",staticClass:"grid-box",attrs:{"key-expr":"id","data-source":_vm.kpiScheGrid.dataSource,"allow-column-resizing":true,"column-resizing-mode":'nextColumn',"show-borders":false,"show-column-headers":true,"show-column-lines":true,"show-row-lines":true,"row-alternation-enabled":false,"hover-state-enabled":true,"word-wrap-enabled":true,"no-data-text":this.$_msgContents('CMN_NO_DATA'),"selected-row-keys":_vm.kpiScheGrid.selectedRowKeys,"width":"100%"},on:{"selection-changed":_vm.onSelectionChanged}},[_c('DxLoadPanel',{attrs:{"enabled":true}}),_c('DxScrolling',{attrs:{"mode":"virtual"}}),_c('DxSelection',{attrs:{"mode":"single","allow-select-all":true,"show-check-boxes-mode":"always","select-all-mode":"allPages"}}),_c('DxHeaderFilter',{attrs:{"visible":true}}),_c('DxColumn',{attrs:{"data-field":"scheYmd","caption":"기준년월","allow-sorting":false,"alignment":"center","allow-header-filtering":false,"allow-filtering":true,"data-type":"date","format":"yyyy-MM","editor-options":{
				dateType: 'date',
				calendarOptions: {
					zoomLevel: 'year',
					minZoomLevel: 'decade',
					maxZoomLevel: 'year',
				},
			}}}),_c('DxColumn',{attrs:{"caption":"생산성평가계획명","data-field":"scheNm","alignment":"center","allowEditing":false,"allowFiltering":true}}),_c('DxColumn',{attrs:{"caption":"스케줄적용여부","data-field":"agtScheApply","alignment":"center","allowEditing":false,"allowFiltering":true}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }